var Constants = {};

Constants.STATUS = {
  REG: 'REG',
  PRE: 'PRE',
  CCL: 'CCL',
  FIN: 'FIN',
  DFIN: 'DFIN',
  UPAY: 'UPAY',
  CUPAY: 'CUPAY',
};
 
Constants.STATUS_NAME = {
  REG: '予約済',
  PRE: '事前通知済',
  CCL: 'キャンセル',
  FIN: '受取済',
  DFIN: '発送済',
  UPAY: '未決済',
  CUPAY: 'キャンセル未決済',
};
 
Constants.STATUS_NAME_USER = {
  REG: '予約済み',
  PRE: '予約済み',
  CCL: 'キャンセル',
  FIN: '受取済み',
  DFIN: '発送済み',
  UPAY: '未決済',
  CUPAY: 'キャンセル',
};

Constants.PAPER_ORDER = 'PAPER_ORDER';

Constants.METHOD = {
};

Constants.METHOD_NAME = {
};

Constants.CLASS = {
  ADMIN: '1',
  SHOP: '2',
};

Constants.CLASS_NAME = {
  '1': '管理者',
  '2': '店舗',
  ADMIN: '管理者',
  SHOP: '店舗',
};

Constants.CHANNEL = {
};

Constants.HEADING = {
  ADULT_NAME: 'お客様の人数を入力してください',
  CHILD_NAME: 'そのうち3歳以下のお子様の人数',
  COUNTER_NAME: 'カウンター席でもよろしいでしょうか',
  ADULT_SHORT: '大小',
  CHILD_SHORT: '未',
  COUNTER_SHORT: 'カウンター',
}

Constants.COUNTER = {
  COUNTER: '1',
  TABLE: '2',
};

Constants.COUNTER_NAME = {
  '1': 'OK',
  '2': 'NG',
  COUNTER: 'OK',
  TABLE: 'NG',
};

Constants.COLUMN = {
  ADULT: 'data1',
  CHILD: 'data2',
  COUNTER: 'data3',
};

Constants.SAIBAN_CLASS = {
  RECEIPT: 'RECEIPT',
};

Constants.RECEIPT_NUM = {
};

Constants.SHOP_ID = 1;

Constants.REQUEST = "request";
Constants.CANCEL = "cancel";
Constants.USER = "user";
Constants.PLAN = "plan";
Constants.CONFIRM = "confirm";
Constants.ORDERFINISH = "orderfinish";
Constants.HISTORY = "history";
Constants.CHANGE = "change";
Constants.ORDERUSERCHANGE = "orderuserchange";
Constants.ORDERSHOPCHANGE = "ordershopchange";
Constants.CHANGEFINISH = "changefinish";
Constants.RECEIVESELECT = "receiveselect";
Constants.RECEIVE = "receive";
Constants.RECEIVEFINISH = "receivefinish";
Constants.USERCHANGE = "userchange";
Constants.EMPLOYEE = "employee";
Constants.MEMBERFINISH = "memberfinish";
Constants.PAYMENT = "payment";

Constants.SETTING = {
  INTERVAL_ABS: "INTERVAL_ABS",
  INTERVAL_CCL: "INTERVAL_CCL",
  INTERVAL_BATCH: "INTERVAL_BATCH",
  CAL_DISP_DEFAULT_START: "CAL_DISP_DEFAULT_START",
  CAL_DISP_DEFAULT_END: "CAL_DISP_DEFAULT_END",
  CAL_LINE_DEFAULT_START: "CAL_LINE_DEFAULT_START",
  CAL_LINE_DEFAULT_END: "CAL_LINE_DEFAULT_END",
  OPEN_DISP_FLG: "OPEN_DISP_FLG",
  OPEN_LINE_FLG: "OPEN_LINE_FLG",
  OPEN_WAIT_FLG: "OPEN_WAIT_FLG",
  LIMIT_PRE: "LIMIT_PRE",
  OPERATE_DATE: "OPERATE_DATE",
}

Constants.OPEN = "1";
Constants.CLOSE = "2";

Constants.ACTIVE_BUTTONS = {
  'UPAY': [],
  'REG': [
    'FIN',
    'DFIN',
  ],
  'PRE': [
    'FIN',
    'DFIN',
  ],
  'CCL': [],
  'FIN': [],
  'DFIN': [],
  'CUPAY': [],
};

Constants.COLOR = {
  '1': 'info',
  '2': 'success',
  BUS: 'info',
  TRAIN: 'success',
};

Constants.EARLY_DISCOUNT = {
  OFF: '0',
  ON: '1'
}

Constants.RECEIVE_TIME = {
  NONE: '0',
  AM9: '1',
  AM11: '2',
  PM1: '3',
  PM3: '4',
  PM5: '5',
  PM7: '6',
  AM10: '7',
  PM12: '8',
  PM14: '9',
  PM16: '10',
}

Constants.RECEIVE_TIME_NAME = {
  '0': '指定無し',
  '1': '午前9時～午前11時',
  '2': '午前11時～午後1時',
  '3': '午後1時～午後3時',
  '4': '午後3時～午後5時',
  '5': '午後5時～午後7時',
  '6': '午後7時以降',  
  '7': '10時～12時',
  '8': '12時～14時',
  '9': '14時～16時',
  '10': '16時～18時',  
  NONE: '指定無し',
  AM9: '午前9時～午前11時',
  AM11: '午前11時～午後1時',
  PM1: '午後1時～午後3時',
  PM3: '午後3時～午後5時',
  PM5: '午後5時～午後7時',
  PM7: '午後7時以降',
  AM10: '10時～12時',
  PM12: '12時～14時',
  PM14: '14時～16時',
  PM16: '16時～18時',
}

Constants.MAX_CALL = 10;
Constants.MAX_ABS = 10;
Constants.MAX_PRE = 4;

Constants.STORE_URL = 'https://www.daikyo-plaza.jp';
Constants.STORE_URL_LINKSTR = 'ダイキョーバリューの情報はwebサイトをチェック';

Constants.PRODUCT_IMAGE_DIR = '/images/product/';
Constants.PLAN_IMAGE_DIR = '/images/plan/';

Constants.PRIVACYPOLICY_URL = 'https://www.daikyo-plaza.jp/123644.html';
Constants.SPECIFIED_COMMERCIAL_URL = '';
Constants.USER_GUIDE_URL = '';


Constants.PRODUCT_DIPT_COLOROPTION = [
  { value: 'blue', label: 'Blue', color: '#0052CC'},
  { value: 'purple', label: 'Purple', color: '#5243AA' },
  { value: 'red', label: 'Red', color: '#FF5630' },
  { value: 'orange', label: 'Orange', color: '#FF8B00' },
  { value: 'yellow', label: 'Yellow', color: '#FFC400' },
  { value: 'green', label: 'Green', color: '#36B37E' },
  { value: 'gray', label: 'Gray', color: '#808080' },
];

Constants.FLAG = {
  OFF: '0',
  ON: '1',
  BOTH: '2'
}

Constants.FLAG_MARK = {
  '0': '-',
  '1': '○',
  '2': '△',
  OFF: '-',
  ON: '○',
  BOTH: '△'
}

Constants.FLAG_NAME = {
  '0': 'なし',
  '1': 'あり',
  '2': '選択可',
  OFF: 'なし',
  ON: 'あり',
  BOTH: '選択可'
}

Constants.PRODUCT_DEPT_DEFAULT = '999';

// 固定の通知設定
Constants.NOTICE_ID_FIXED = {
  NONE: 0,
  EVERY: 1,
  0: "通知なし",
  1: "注文都度"
}

Constants.NOTICE_TYPE = {
  NONE : '0',
  COUNT : '1',
  DETAIL : '2',
}
Constants.NOTICE_TYPE_NAME = {
  '0' : 'なし',
  '1' : '注文件数',
  '2' : '注文詳細',
  NONE : 'なし',
  COUNT : '注文件数',
  DETAIL : '注文詳細',
}

Constants.HIDE_NG_SHOP = '1';

export default Constants;